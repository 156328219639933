import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { SessionService } from './session.service';
import { UtilService } from './util.service';
import { CandyService } from '../candy.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private session: SessionService,
    private util: UtilService,
    private candy: CandyService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.util.getCookie('admin_token');
    const refreshToken = this.util.getCookie('admin_refresh_token');

    if (accessToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status === 401 && this.session.isLoggedIn()) {
          if (error.url.endsWith('refresh-token')) {
            this.candy.customerLogout();
            return throwError(error);
          }
          
          return this.candy.createRequest("POST", 'api/refresh-token', { refresh_token: refreshToken }).pipe(
            switchMap((response) => {
              this.util.setCookie('admin_token', response?.data?.access_token);
              this.util.setCookie('admin_refresh_token', response?.data?.refresh_token);
              
              req = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${response?.data?.access_token}`,
                },
              });

              // Retry the request with the updated token
              return next.handle(req);
            }),
            catchError((refreshError) => {
              console.error('Refresh token error: ', refreshError);
              return throwError(refreshError);
            })
          );
        }

        return throwError(error);
      })
    );
  }
}
