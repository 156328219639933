import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../loader.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  message = new Subject<string>();
  type = new Subject<string>();

  constructor(private loaderService: LoaderService) { }

  showMessage(message: string, type: string, autohide = true): void {
    if (message) {
      // remove squashed text
      message = message.replace('has', ' has');
    }
    this.message.next(message);
    this.type.next(type);
    this.loaderService.setShowMessage(true);
    if (autohide) {
      setTimeout(() => {
        this.loaderService.setShowMessage(false);
        setTimeout(() => {
          this.message.next(null);
          this.type.next(null);
        }, 1000);
      }, 4000);
    }
  }
}
